var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"create-examination"},[_c('div',{staticClass:"examination-header"},[_c('el-button',{attrs:{"type":"primary"},on:{"click":_vm.createExamination}},[_vm._v("创建考试")])],1),_c('el-table',{staticClass:"customTable",staticStyle:{"width":"100%","margin-top":"20px","flex":"1"},attrs:{"data":_vm.tableData,"height":"1%","size":"medium","header-cell-style":{
      fontWeight: 'normal',
      height: '60px',
      color: '#666666',
      background: '#F6F6F6',
      fontSize: '16px',
    },"cell-style":{ fontSize: '16px', color: '#333333', height: '70px' }}},[_c('el-table-column',{attrs:{"prop":"exam_name","label":"名称","width":"320","align":"center"}}),_c('el-table-column',{attrs:{"prop":"examination_room_name","label":"考场","width":"220","align":"center"}}),_c('el-table-column',{attrs:{"prop":"start_time","label":"开始时间","width":"240","align":"center"}}),_c('el-table-column',{attrs:{"prop":"end_time","label":"结束时间","width":"240","align":"center"}}),_c('el-table-column',{attrs:{"align":"center","label":"操作"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('el-link',{attrs:{"type":"success","underline":false},on:{"click":function($event){return _vm.lookExam(scope.row)}}},[_vm._v("查看详情")]),_c('el-link',{attrs:{"type":"success","underline":false},on:{"click":function($event){return _vm.editExam(scope.row)}}},[_vm._v("编辑")]),_c('el-link',{attrs:{"type":"success","underline":false},on:{"click":function($event){return _vm.stopExam(scope.row)}}},[_vm._v("停止考试")]),_c('el-link',{attrs:{"type":"danger","underline":false},on:{"click":function($event){return _vm.deleteExam(scope.row)}}},[_vm._v("删除")])]}}])})],1),_c('el-pagination',{staticClass:"pager-center",staticStyle:{"margin":"20px 0 30px","text-align":"center"},attrs:{"current-page":_vm.examinationPages.currentPageNum,"page-size":_vm.examinationPages.eachPageNum,"total":_vm.examinationPages.total,"layout":"prev, pager, next, jumper"},on:{"current-change":_vm.CurrentChange}}),_c('el-dialog',{attrs:{"close-on-click-modal":false,"close-on-press-escape":false,"title":_vm.examTitle,"custom-class":"green-dialog","visible":_vm.examDlalog,"width":"80%"},on:{"update:visible":function($event){_vm.examDlalog=$event}}},[_c('el-table',{staticClass:"customTable",staticStyle:{"width":"100%","margin-top":"20px","flex":"1"},attrs:{"data":_vm.examStudent,"height":"500px","size":"medium","header-cell-style":{
        fontWeight: 'normal',
        height: '60px',
        color: '#666666',
        background: '#F6F6F6',
        fontSize: '16px',
      },"cell-style":{ fontSize: '16px', color: '#333333', height: '70px' }}},[_c('el-table-column',{attrs:{"prop":"nickname","label":"学生","width":"200","align":"center"}}),_c('el-table-column',{attrs:{"prop":"id_number","label":"身份证号","width":"300","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('span',[_vm._v(_vm._s(scope.row.id_number == null ? "无" : scope.row.id_number))])]}}])}),_c('el-table-column',{attrs:{"prop":"paper_name","label":"试卷","width":"300","align":"center"}}),_c('el-table-column',{attrs:{"prop":"room_name","label":"考场","width":"300","align":"center"}}),_c('el-table-column',{attrs:{"align":"center","label":"操作"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('el-link',{attrs:{"type":"danger","underline":false},on:{"click":function($event){return _vm.deleteExamStudentData(scope.row)}}},[_vm._v("删除")])]}}])})],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }