<template>
  <div class="create-examination">
    <div class="examination-header">
      <el-button type="primary" @click="createExamination">创建考试</el-button>
    </div>
    
    <el-table
      class="customTable"
      :data="tableData"
      style="width: 100%; margin-top: 20px; flex: 1"
      height="1%"
      size="medium"
      :header-cell-style="{
        fontWeight: 'normal',
        height: '60px',
        color: '#666666',
        background: '#F6F6F6',
        fontSize: '16px',
      }"
      :cell-style="{ fontSize: '16px', color: '#333333', height: '70px' }"
    >
 
      <el-table-column prop="exam_name" label="名称" width="320" align="center">
      </el-table-column>
      <el-table-column
        prop="examination_room_name"
        label="考场"
        width="220"
        align="center"
      ></el-table-column>
      <el-table-column
        prop="start_time"
        label="开始时间"
        width="240"
        align="center"
      ></el-table-column>
      <el-table-column
        prop="end_time"
        label="结束时间"
        width="240"
        align="center"
      ></el-table-column>
      <el-table-column align="center" label="操作">
        <template slot-scope="scope">
          <el-link
            type="success"
            :underline="false"
            @click="lookExam(scope.row)"
            >查看详情</el-link
          >
          <el-link
            type="success"
            :underline="false"
            @click="editExam(scope.row)"
            >编辑</el-link
          >
          <el-link
            type="success"
            :underline="false"
            @click="stopExam(scope.row)"
            >停止考试</el-link
          >
          <el-link
            type="danger"
            :underline="false"
            @click="deleteExam(scope.row)"
            >删除</el-link
          >
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      class="pager-center"
      style="margin: 20px 0 30px; text-align: center"
      :current-page="examinationPages.currentPageNum"
      :page-size="examinationPages.eachPageNum"
      :total="examinationPages.total"
      layout="prev, pager, next, jumper"
      @current-change="CurrentChange"
    >
    </el-pagination>
    <el-dialog
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      :title="examTitle"
      custom-class="green-dialog"
      :visible.sync="examDlalog"
      width="80%"
    >
      <el-table
        class="customTable"
        :data="examStudent"
        style="width: 100%; margin-top: 20px; flex: 1"
        height="500px"
        size="medium"
        :header-cell-style="{
          fontWeight: 'normal',
          height: '60px',
          color: '#666666',
          background: '#F6F6F6',
          fontSize: '16px',
        }"
        :cell-style="{ fontSize: '16px', color: '#333333', height: '70px' }"
      >
        <el-table-column
          prop="nickname"
          label="学生"
          width="200"
          align="center"
        >
        </el-table-column>
        <el-table-column
          prop="id_number"
          label="身份证号"
          width="300"
          align="center"
        >
          <template slot-scope="scope">
            <span>{{
              scope.row.id_number == null ? "无" : scope.row.id_number
            }}</span>
          </template>
        </el-table-column>
        <el-table-column
          prop="paper_name"
          label="试卷"
          width="300"
          align="center"
        >
        </el-table-column>
        <el-table-column
          prop="room_name"
          label="考场"
          width="300"
          align="center"
        ></el-table-column>
        <el-table-column align="center" label="操作">
          <template slot-scope="scope">
            <el-link
              type="danger"
              :underline="false"
              @click="deleteExamStudentData(scope.row)"
              >删除</el-link
            >
          </template>
        </el-table-column>
      </el-table>
    </el-dialog>
  </div>
</template>

<script>
import {
  showExamList,
  delExamData,
  showExamStudentData,
  delExamStudentData,
  endExam
} from "@/utils/apis";
export default {
  name: "Index",
  data() {
    return {
      examTitle: "",
      examDlalog: false,
      examinationPages: {
        currentPageNum: 1,
        eachPageNum: 10,
        total: 0,
      },
      // examinationStudentPages: {
      //   currentPageNum: 1,
      //   eachPageNum: 10,
      //   total: 0,
      // },
      tableData: [],
      examStudent: [],
      delid: void 0,
    };
  },
  mounted() {
    this.showExamListData();
  },
  methods: {
    showExamListData() {
      let params = {
        page: this.examinationPages.currentPageNum,
        limit: this.examinationPages.eachPageNum,
      };
      showExamList(params).then((res) => {
        if (res.code === 200) {
          this.tableData = res.data.list;
          this.examinationPages.total = res.data.total;
        }
      });
    },
    createExamination() {
      this.$router.push({
        path: "/examManage/createExamination",
      });
    },
    lookExam(row) {
      this.examDlalog = true;
      this.examTitle = "考场学生详情";
      this.delid = row.id;
      let params = {
        id: row.id,
      };
      this.showExamStudentList(params);
    },
    showExamStudentList(params) {
      showExamStudentData(params).then((res) => {
        if (res.code === 200) {
        console.log(res.data)
          this.examStudent = res.data.list;
          // this.examinationStudentPages.total = res.data.total;
        }
      });
    },
    deleteExamStudentData(row) {
      this.$confirm(`是否删除该条考试信息`, "删除", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        customClass: "green-message-box",
        type: "warning",
      })
        .then(() => {
          let params = {
            id: row.id,
          };
          delExamStudentData(params).then((res) => {
            if (res.code === 200) {
              this.$message({
                type: "success",
                message: res.msg,
              });

              let del = {
                id: this.delid,
              };
              this.showExamStudentList(del);
            }
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
    editExam(row) {
      this.$router.push({
        path: "/examManage/createExamination",
        query: { id: row.id },
      });
    },
    stopExam(row){
      this.$confirm(`是否停止该场考试`, "停止", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        customClass: "green-message-box",
        type: "warning",
      })
        .then(() => {
          let params = {
            id: row.id,
          };
          endExam(params).then((res) => {
            if (res.code === 200) {
              this.$message({
                type: "success",
                message: res.msg,
              });
            }
            this.showExamListData();
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消停止",
          });
        });
    },
    deleteExam(row) {
      this.$confirm(`是否删除该条考试信息`, "删除", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        customClass: "green-message-box",
        type: "warning",
      })
        .then(() => {
          let params = {
            id: row.id,
          };
          delExamData(params).then((res) => {
            if (res.code === 200) {
              this.$message({
                type: "success",
                message: res.msg,
              });
            }
            this.showExamListData();
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
    CurrentChange(val) {
      this.examinationPages.currentPageNum = val;
      this.showExamListData();
    },
    // CurrentStudentChange(val) {
    //   this.examinationStudentPages.currentPageNum = val;
    //   this.lookExam();
    // },
  },
};
</script>

<style lang="scss" scoped>
.create-examination {
  padding: 0px 20px;
  box-sizing: border-box;
  height: 100%;
  display: flex;
  flex-direction: column;
  .examination-header {
    display: flex;
    justify-content: flex-end;
  }
}
</style>
